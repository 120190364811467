.table-container {
    overflow: visible !important; /* Ensure all columns are visible */
    width: 100%;
    max-width: none; /* Allow grid to expand beyond container */
}

/* Force grid to display all columns without scrolling */
.e-grid {
    overflow: visible !important;
    white-space: nowrap !important;
    min-width: 100%;
}

/* Ensure table expands in print */
@media print {
    .e-grid {
        width: auto !important;
        overflow: visible !important;
    }
}
