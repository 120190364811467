.rowC{display:flex; flex-direction:row;}

.nav-tabs .nav-link {
    background-color: transparent !important;
    color: #000000 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  
.nav-tabs .nav-link.active {
  border-bottom: solid 4px white !important;
  color: rgb(109, 109, 238) !important;
  font-weight: bold;
}

/* .form-control { 
  border-radius: 30px !important;
  background-color: #f4f6f5 !important;
  width: 100% !important;
} */.rowC{display:flex; flex-direction:row;}

.nav-tabs .nav-link {
  background-color: transparent !important;
  color: #000000 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav-tabs .nav-link.active {
border-bottom: solid 4px white !important;
color: rgb(109, 109, 238) !important;
font-weight: bold;
}

/* .form-control { 
border-radius: 30px !important;
background-color: #f4f6f5 !important;
width: 100% !important;
} */

/* .thistable td {
padding: 0% ;
margin: 0;
} */

.thisform {
margin: 0;
}


li{
margin: 1rem;
}

/* .table {
table-layout: fixed;
width: 100%;
} */

/* .thistable td {
  padding: 0% ;
  margin: 0;
} */

.thisform {
  margin: 0;
}


li{
  margin: 1rem;
}

/* .table {
  table-layout: fixed;
  width: 100%;
} */