.print-container {
    width: 210mm;
    /* A4 width */
    min-height: 297mm;
    /* A4 height */
    margin: auto;
    padding: 20px;
    border: 2px solid black;
    border-radius: 10px;
    background: #fff;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.header h1 {
    color: #333;
    text-transform: uppercase;
}

.section {
    margin: 20px 0;
    text-align: center;
}

h3 {
    color: #444;
    border-bottom: 2px solid #333;
    padding-bottom: 5px;
    display: inline-block;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background: #f8f9fa;
}

.styled-table th,
.styled-table td {
    border: 1px solid black;
    padding: 10px;
    text-align: center;
}

.styled-table th {
    background: #343a40;
    color: white;
    font-weight: bold;
}

.comment-box {
    border: 2px solid black;
    padding: 15px;
    border-radius: 5px;
    background: #f1f1f1;
    text-align: left;
}