/* Dialog Box Container */
#dialogBox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
}

/* Hidden by default */
.hidden {
    display: none;
}

/* Dialog Box Header */
#dialogBox h5 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Dialog Box Content */
.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* Adds spacing between checkboxes */
    padding: 10px 0;
}

/* Align checkboxes and labels */
.form-check {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Spacing between checkbox and label */
}

/* Adjust checkbox size */
.form-check input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

/* Buttons Styling */
.dialog-content button {
    width: 100%;
    margin-top: 10px;
    padding: 8px;
    border-radius: 5px;
}

/* Print Button */
.dialog-content .btn-primary {
    background: #007bff;
    border: none;
}

.dialog-content .btn-primary:hover {
    background: #0056b3;
}

/* Cancel Button */
.dialog-content .btn-secondary {
    background: #6c757d;
    border: none;
}

.dialog-content .btn-secondary:hover {
    background: #5a6268;
}