/* Container */
#Viscosity_Grid .table-container {
    padding: 10px;
    overflow: hidden;
    max-height: 100vh;
    height: 50vh;
    /* Fixed typo */
}

/* Scrollable wrapper */
#Viscosity_Grid .table-scroll-wrapper {
    width: 100%;
    overflow-x: auto;
    /* Enables horizontal scrolling */
    overflow-y: auto;
    /* Enables vertical scrolling */
    max-height: 100vh;
    height: 50vh;
    /* Fixed typo */
    /* Fixed height for vertical scrolling */
    border: 1px solid #ccc;
    background-color: white;
}

/* Table */
#Viscosity_Grid .excel-table {
    width: max-content;
    border-collapse: collapse;
    /* Make it look like Excel */
}

.excel-table td[contenteditable="true"] {
    text-align: right;
    /* Keeps text aligned to the right */
    direction: ltr;
    /* Ensures normal left-to-right typing */
}

.excel-table td:not([contenteditable="true"]) {
    background-color: #d3d3d3;
}

/* Headers */
.excel-table th {
    background-color: #1b527b;
    color: #fff;
    /* Light gray, similar to Excel */
    font-weight: bold;
    text-align: center;
    border: 1px solid #bfbfbf;
    min-width: 20px;
    /* Prevent shrinking */
    max-width: fit-content;
    position: relative;
}

/* Fix header at the top */
.excel-table thead {
    position: sticky;
    top: 0;
    z-index: 10;
}

/* Fix the first column */
/* .excel-table td:first-child,
.excel-table th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 2px solid #d1d5db;
} */

/* Table cells */
.excel-table td {
    border: 1px solid #d1d5db;
    text-align: right;
    padding: 1px;
    min-width: 20px;
    /* Ensure a minimum width */
    max-width: fit-content;
    background-color: white;
    /* Default white background */
    font-family: Arial, sans-serif;
    /* Excel-like font */
}

/* Alternating row colors like Excel */
.excel-table tbody tr:nth-child(odd) {
    background-color: #f9fafb;
}

/* Selected cell styling */
.excel-table td:focus {
    outline: 2px solid #2563eb;
    background-color: #eef2ff;
}

/* Column resizer */
.excel-table .column-resizer {
    position: absolute;
    right: -3px;
    top: 0;
    width: 6px;
    height: 100%;
    cursor: col-resize;
    background-color: transparent;
    z-index: 10;
}

.excel-table .column-resizer:hover {
    background-color: #aaa;
}

/* Drag selection highlight */
.excel-table .selected {
    background-color: #cce5ff !important;
    /* Light blue selection */
}

.excel-table td[contenteditable="true"] {
    text-align: right;
    /* Keeps text aligned to the right */
    direction: ltr;
    /* Ensures normal left-to-right typing */
}

.excel-table td:not([contenteditable="true"]).selected {
    background-color: #d3d3d3;
}

.excel-table .selected {
    background-color: #cce5ff !important;
    /* Light blue selection */
}

/* *********************** Cavity CSS ***********************/
#Cavity_Grid .table-container {
    overflow: hidden;
    max-height: 100vh;
    height: 30vh;
    /* Fixed typo */
}

/* Scrollable wrapper */
#Cavity_Grid .table-scroll-wrapper {
    width: 95%;
    overflow-x: auto;
    /* Enables horizontal scrolling */
    overflow-y: auto;
    /* Enables vertical scrolling */
    max-height: 100vh;
    height: 30vh;
    /* Fixed typo */
    /* Fixed height for vertical scrolling */
    border: 1px solid #ccc;
    background-color: white;
}

/* Fix the first column */
/* .excel-table td:first-child,
.excel-table th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 2px solid #d1d5db;
} */

/******************* Pressure Drop CSS *************************/
/* Container */
#Pressure_Grid .table-container {
    padding: 10px;
    overflow: hidden;
    max-height: 100vh;
    height: 50vh;
    /* Fixed typo */
}

/* Scrollable wrapper */
#Pressure_Grid .table-scroll-wrapper {
    width: 100%;
    overflow-x: auto;
    /* Enables horizontal scrolling */
    overflow-y: auto;
    /* Enables vertical scrolling */
    max-height: 100vh;
    height: 50vh;
    /* Fixed typo */
    /* Fixed height for vertical scrolling */
    border: 1px solid #ccc;
    background-color: white;
}

/* Table */
#Pressure_Grid .excel-table {
    width: max-content;
    border-collapse: collapse;
    /* Make it look like Excel */
}

/* Cosmetic CSS */
#Cosmetic_Grid .table-container {
    overflow: hidden;
    max-height: 100vh;
    height: 30vh;
    /* Fixed typo */
}

/* Scrollable wrapper */
#Cosmetic_Grid .table-scroll-wrapper {
    width: 95%;
    overflow-x: auto;
    /* Enables horizontal scrolling */
    overflow-y: auto;
    /* Enables vertical scrolling */
    max-height: 100vh;
    height: 30vh;
    /* Fixed typo */
    /* Fixed height for vertical scrolling */
    border: 1px solid #ccc;
    background-color: white;
}

/* ColdRunner_Grid CSS */
#ColdRunner_Grid .table-container {
    overflow: hidden;
    max-height: 100vh;
    height: 30vh;
    /* Fixed typo */
}

/* Scrollable wrapper */
#ColdRunner_Grid .table-scroll-wrapper {
    width: 95%;
    overflow-x: auto;
    /* Enables horizontal scrolling */
    overflow-y: auto;
    /* Enables vertical scrolling */
    max-height: 100vh;
    height: 30vh;
    /* Fixed typo */
    /* Fixed height for vertical scrolling */
    border: 1px solid #ccc;
    background-color: white;
}